<template>
  <div>
    <!-- select 2 demo -->
    <div class="m-2">
      <!-- Table Top -->
      <b-container>
        <b-row class="mt-1">
          <b-col xl="2" lg="3" md="4" sm="12" cols="12">
            <b-button
              v-if="rights.add"
              class="block"
              @click="Form()"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="align-middle"> Add Branch</span>
            </b-button>
          </b-col>

          <b-col
            class="mt-lg-0 mt-md-0 mt-sm-1 mt-1"
            xl="8"
            lg="6"
            md="4"
            sm="12"
            cols="12"
          >
            <b-form-group class="ml-xl-2 ml-lg-2 ml-md-0 ml-sm-0 ml-0">
              <b-input-group>
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>

                <b-form-input v-model="searchQuery" placeholder="Search...">
                </b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <!-- <b-col class="text-right" xl="2" lg="3" md="4" sm="12" cols="12">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
            >
              <feather-icon icon="FilterIcon" class="mr-50" />
              <span class="align-middle"> Filter</span>
            </b-button>
          </b-col> -->
        </b-row>
        <b-row align-h="center" v-if="dataLoading">
          <b-spinner
            class="m-5"
            style="width: 4rem; height: 4rem"
            type="grow"
            variant="primary"
            label="Spinning"
          ></b-spinner>
        </b-row>
        <b-row
          align-h="center"
          v-else-if="filters.length == 0"
          style="height: 20vh"
        >
          <h1 class="mt-1 mb-1" style="align-self: center">No records found</h1>
        </b-row>
        <b-row class="mt-2" align-h="start" v-else>
          <!-- <b-col lg="3" md="4"> 
    <a>
   <b-card
    no-body
    @click="ClickAble1()"  
    style="max-width: 25rem;"
    class="card  initial my-2 "
    v-bind:class="{clickcard: active1}"
  >
  
  <b-link>
    <img class="card-img " src="https://dk0pm9zdlq16s.cloudfront.net/8cbeb020-efb6-4a78-993c-871a94ddbbac.png" alt="Card image">
               

              </b-link>
     
            

   </b-card> 
   </a>
   
  </b-col>   -->
          <b-col
            v-for="c in filters"
            :key="c"
            class="ml-lg-0 ml-md-0 ml-sm-0 ml-2"
            xl="3"
            lg="4"
            md="4"
            sm="12"
            cols="12"
          >
            <a>
              <b-card
                no-body
                style="max-width: 25rem"
                class="initial"
                v-bind:class="{ clickcard: active1 }"
                @click="rights.edit && Edit(c)"
              >
                <!-- <b-avatar
      size="70px"
      :variant="activeAvatar1"
      text="B"
      v-bind:class="{clickcard: active1}"
    >
  
    </b-avatar> -->
                <b-card
                  align-v="center"
                  bg-variant="primary"
                  text-variant="white"
                  class="text-center"
                  style="height: 150px; border-radius: 5px 5px 0px 0px"
                >
                  <b-card-title
                    ><h1
                      class="my-lg-3 my-md-* my-sm-3 my-3"
                      style="color: white"
                    >
                      {{ c.branch }}
                    </h1>
                  </b-card-title>
                </b-card>
                <b-card-title
                  class="pl-1"
                  v-bind:class="{ clickcard: active1 }"
                >
                  <b>{{ c.name }}</b>
                </b-card-title>

                <b-card-text class="p-1">
                  <!-- <b-row align-h="start">
              <b-col md="12">
                <b-card-text >
                  <b>The Smart School</b> 
                </b-card-text>
              </b-col>
            </b-row> -->
                  <b-row align-h="start" style="margin-top: 5px">
                    <b-col md="12">
                      <b-card-text>
                        <feather-icon icon="PhoneIcon" /> {{ c.mobile }}
                      </b-card-text>
                    </b-col>
                  </b-row>
                  <b-row align-h="start" style="margin-top: 5px">
                    <b-col md="12">
                      <b-card-text>
                        <feather-icon icon="MailIcon" /> {{ c.email }}
                      </b-card-text>
                    </b-col>
                  </b-row>
                  <b-row align-h="start" style="margin-top: 5px">
                    <b-col md="12">
                      <b-card-text>
                        <feather-icon icon="GlobeIcon" />
                        <a href="#">
                          {{ c.website }}
                        </a>
                      </b-card-text>
                    </b-col>
                  </b-row>
                  <b-row align-h="start" style="margin-top: 5px">
                    <b-col md="12">
                      <b-card-text style="height: 50px">
                        <feather-icon icon="HomeIcon" /> {{ c.address }}
                      </b-card-text>
                    </b-col>
                  </b-row>

                  <b-row align-h="end">
                    <b-col md="0">
                      <div>
                        <b-button
                          v-if="rights.edit"
                          @click="Edit(c)"
                          v-b-tooltip.hover.top
                          title="Edit"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          class="mr-1 mt-1 btn-icon rounded-circle"
                        >
                          <feather-icon icon="EditIcon" />
                        </b-button>

                        <b-button
                          v-if="rights.delete"
                          @click="Delete(c.id)"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-danger"
                          v-b-tooltip.hover.top
                          title="Delete"
                          class="mr-1 mt-1 btn-icon rounded-circle"
                        >
                          <feather-icon icon="Trash2Icon" />
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-text>
                <!-- <hr>
<b-card-text >
    <strong> Free </strong> 5 products
    </b-card-text> -->
              </b-card>
            </a>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BContainer,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BInputGroupPrepend,
  BFormInput,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
//import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from "@core/utils/filter";
//import useAllOrdersList from './useAllOrdersList'
//import orderStoreModule from '../allordersStoreModule'
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    //Multiselect,

    BCard,
    BRow,
    BCol,
    //BAvatar,
    BFormInput,
    BButton,
    BFormGroup,
    //BFormGroup,
    // BFormCheckbox,
    //BForm,
    BContainer,
    //BCardTitle,
    BCardText,
    BInputGroupPrepend,
    BInputGroup,
    //BMediaAside,
    //BImg ,
    // BMedia,
    //BAvatar,
    //BLink,
    BSpinner,
    //BBadge,
    //BDropdown,
    //BDropdownItem,
    //BPagination,
    //vSelect,
    //flatPickr,
    //'gallery': VueGallery
  },
  props: {
    /* modalFilter: {
    type: [String, null],
    default: null,
    },
    modalFilterOptions: {
    type: Array,
    required: true,
    }*/
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  async created() {
    let right = {};
    this.$store.state.menu.forEach((el) => {
      el.children.forEach((ch) => {
        right[ch.route] = {
          view: ch.view,
          add: ch.add,
          edit: ch.edit,
          delete: ch.delete,
        };
      });
    });
    this.$store.commit("setRights", right);
    // console.log(this.$store.state.rights);

    if (!this.$store.state.rights[this.$route.name]) {
      // console.log(this.$store.state.rights[this.$route.name]);
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
    }
    this.LoadData();
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),
    filters: function () {
      return this.items.filter((pro) => {
        return (
          pro.branch.toLowerCase().match(this.searchQuery.toLowerCase()) ||
          pro.name.toLowerCase().match(this.searchQuery.toLowerCase())
        );
      });
    },
  },
  data() {
    return {
      rights: {},
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      totalRows: 0,
      dataLoading: false,
      errors: {
        status: false,
      },
      statusOptions: ["In stock", "Out stock"],
      filterData: "",
      date: {
        range: "",
      },
      index: null,
      cover: "",
      docs: [],
      partners: [],
      fields: [
        "#",
        { label: "Flavour", key: "Name" },
        { label: "Status", key: "Status" },
        "actions",
      ],
      items: [],
      selected: null,
      filterOptions: [
        { text: "Most sold product", value: "mostSold" },
        { text: "Least sold product", value: "leastSold" },
        { text: "Most popular product", value: "mostPopular" },
        { text: "Least popular product", value: "leastPopular" },
        { text: "Most profitable product", value: "mostProfitable" },
      ],
      rangeDate: null,
      myObj: {
        flavourId: 0,
        vendorId: this.$store.state.userData.userID,
        Name: "",
        Status: "",
      },
      detailObj: {},
      limit: 0,
    };
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),
    Form() {
      if (this.limit == this.items.length) {
        this.$bvToast.toast("Contact MySkool to add more campuses.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else this.$router.push("/manage-addbranch");
    },
    checkTitle() {
      var elem = this.$refs["title"];
      return (elem.state = this.myObj.Name.length > 2 ? true : false);
    },
    Edit(row) {
      // console.log(row);
      this.$router.push({
        name: "manage-addbranch",
        params: {
          row: { row },
        },
      });
    },
    checkStatus() {
      if (this.myObj.Status == "") {
        return (this.errors.status = true);
      } else {
        return (this.errors.status = false);
      }
    },
    OpenAddModal() {
      this.$bvModal.show("modal-login");
      this.myObj.flavourId = 0;
      this.myObj.vendorId = this.$store.state.userData.userID;
      this.myObj.Name = "";
      this.myObj.Status = "";
    },
    OpenEditModal(row) {
      this.$bvModal.show("modal-login");
      this.myObj.flavourId = row.flavourId;
      this.myObj.vendorId = row.vendorId;
      this.myObj.Name = row.Name;
      this.myObj.Status = row.Status;
    },
    async Delete(id) {
      event.stopPropagation();
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "campuses/" +
            id +
            "?db=" +
            this.$store.state.userData.db +
            "&cId=" +
            this.$store.state.userData.cId,
          message: "Campus deleted successfully.",
          context: this,
          body: null,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData();
      }
    },

    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "campuses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);
      this.dataLoading = false;
      this.$store.commit("setBranches", this.items);
      // console.log(this.$store.state.branches);
      const branch = this.items.find(
        (el) => el.id == this.$store.state.userData.cId
      );
      this.$store.commit("setBranchName", branch.branch);
      // console.log(this.$store.state.branchName);
      this.$store.commit("setCurrentBranch", branch);
      this.limit = this.$store.state.branches[0].limit;
      // console.log("limit", this.limit);

      // to update dropdown

      // var obj2 = {
      //   url:
      //     this.$store.state.domain +
      //     " ?db=" +
      //     this.$store.state.userData.db +
      //     "&cId=" +
      //     this.$store.state.userData.cId,
      //   token: this.$store.state.userData.token,
      // };
      //   const result = await this.get(obj2);
      //   this.$store.commit("setBranches", result);
      //   const data = result.find(
      //     (el) => el.id == this.$store.state.userData.cId
      //   );
      //   this.$store.commit("setBranchName", data.branch);
      // this.$store.commit("setDB", branch.db);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
